(function () {
    angular.module('informaApp')
        .directive('movable', Movable);

    function Movable() {
        return {
            restrict: 'A',
            link(scope, element) {
                element.draggable({
                    drag: (event, ui) => {
                        const margins = this.getElementLeftTopStyle(element, 'margin');

                        const position = this.getElementPositionWithoutMargins(ui.position, margins);
                        const fitPosition = this.fitPositionInWindowBoundaries(position, this.getElementSize(element));

                        ui.position.left = fitPosition.left - margins.left;
                        ui.position.top = fitPosition.top - margins.top;
                    }
                });
            },
            getElementLeftTopStyle(element, cssStyleName) {
                const getMargin = (side) => parseInt(element.css(`${cssStyleName}-${side}`));

                return {
                    left: getMargin('left') || 0,
                    top: getMargin('top') || 0
                };
            },
            getElementSize(element) {
                return {
                    width: element.innerWidth(),
                    height: element.innerHeight()
                };
            },
            getElementPositionWithoutMargins(position, margins) {
                return {
                    left: position.left + margins.left,
                    top: position.top + margins.top
                };
            },
            fitNumberInBoundaries(value, min, max) {
                if (value < min) {
                    return min;
                }

                return value > max ? max : value;
            },
            fitPositionInWindowBoundaries(position, elementSize) {
                const boundaries = {
                    left: 0,
                    top: 0,
                    right: window.innerWidth - elementSize.width,
                    bottom: window.innerHeight - elementSize.height
                };

                return {
                    left: this.fitNumberInBoundaries(position.left, boundaries.left, boundaries.right),
                    top: this.fitNumberInBoundaries(position.top, boundaries.top, boundaries.bottom),
                };
            }
        }
    }
})();
